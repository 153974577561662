<template>
  <div class="content">
    <!-- 新增弹窗答题 -->
    <el-dialog
      :title="$t('sign_answer')"
      :visible.sync="answerRegisterStatus.answerDialogVisible"
      width="680px"
      :custom-class="
        answerRegisterStatus.registerDialogVisible ? 'answerNone' : 'answer'
      "
    >
      <div class="answer-top">
        <div class="answer-top-title">{{ $t("sign_answer") }}</div>
        <img
          class="answer-close"
          src="https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/answer/answer.png"
          alt="图片"
          @click="answerRegisterStatus.answerDialogVisible = false"
        />
      </div>
      <img
        class="answer-bg"
        src="https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/answer.png"
        alt="图片"
      />
      <div class="answer-title">
        {{ answerRegisterStatus.question.content }}
      </div>
      <div
        v-for="(item, index) in answerRegisterStatus.question.options"
        :key="index"
      >
        <div
          :class="
            index === answerRegisterStatus.answerIndex
              ? 'answer-item answer-active'
              : 'answer-item'
          "
          @click="changeAnswerIndex(index, item)"
          v-html="item.content"
        ></div>
      </div>
      <div class="answer-submit" @click="answerSubmit()">
        {{ $t("answer_submit") }}
      </div>
    </el-dialog>
    <!-- 新增弹窗签到 -->
    <el-dialog
      :visible.sync="answerRegisterStatus.registerDialogVisible"
      width="680px"
      class="register"
      @close="answerRegisterStatus.answerDialogVisible = false"
    >
      <div class="register-main">
        <img
          class="register-bg"
          src="https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/register.png"
          alt="图片"
        />
        <img
          class="register-close"
          src="@/assets/images/answer/close.png"
          alt="图片"
          @click="registerClose"
        />
        <div class="register-calendar">
          <div class="register-calendar-title">
            <span></span>
            <div>今日签到成功</div>
            <span></span>
          </div>
          <div class="register-calendar-main">
            <div
              class="register-calendar-week"
              v-for="item in weekList"
              :key="item"
            >
              <div>{{ item }}</div>
            </div>
            <div
              class="register-calendar-day"
              v-for="(item, index) in answerRegisterStatus.dateList"
              :key="index"
            >
              <div
                :class="
                  item.bgColor
                    ? 'register-calendar-day-item lastday'
                    : 'register-calendar-day-item'
                "
              >
                <span v-if="!item.isRmb">{{ item.day }}</span>
                <img
                  v-else
                  class="rmb"
                  src="@/assets/images/answer/￥.png"
                  alt="图片"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["answerRegisterStatus"],
  data() {
    return {
      weekList: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"]
    };
  },
  created() {
    console.log("111", this.answerRegisterStatus);
  },
  methods: {
    // 回答签到问题
    answerSubmit(item) {
      if (!this.optionIds) {
        this.$message({
          message: "请先回答问题",
          type: "warning"
        });
        return;
      }
      const userid = localStorage.getItem("userid");
      console.log(item);
      this.$http
        .post("/api/v1/SigninAnswer", null, {
          params: {
            userId: userid,
            questionId: this.answerRegisterStatus.question.id,
            optionIds: this.optionIds
          }
        })
        .then(res => {
          console.log(res, res.status);
          if ((res, res.status === 200)) {
            this.$emit("answerSubmit");
          }
        });
    },
    changeAnswerIndex(index, item) {
      this.$emit("changeAnswerIndex", index);
      this.optionIds = item.id;
    },
    registerClose() {
      this.$emit("registerClose");
    }
  }
};
</script>

<style scoped>
/* 新增 */
.content >>> .el-dialog__header {
  display: none;
}
.content /deep/ .el-dialog__body {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 14px;
}
.content /deep/.answerNone {
  display: none;
}
.register /deep/ .el-dialog__body {
  padding: 0;
}
.answer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  margin-top: 14px;
  margin-bottom: 24px;
}
.answer-close {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.answer .answer-bg {
  width: 100%;
}
.answer-title {
  font-size: 20px;
  font-weight: 400;
  margin-top: 39px;
  margin-bottom: 32px;
  color: #333333;
}
.answer-item {
  width: calc(100% - 25px);
  height: 65px;
  border-radius: 14px;
  opacity: 1;
  margin-bottom: 21px;
  padding-left: 25px;
  line-height: 65px;
  color: #333333;
  cursor: pointer;
  font-size: 18px;
  /* background: #EAEEFB; */
  border: 1px solid #dcdfe6;
  color: #dcdfe6;
}
.answer-active {
  background: rgba(241, 155, 56, 0.1);
  color: #ff9600;
  border: 1px solid rgba(241, 155, 56, 0.1);
}
.answer-submit {
  margin: 50px auto 30px auto;
  cursor: pointer;
  text-align: center;
  width: 158px;
  height: 51px;
  line-height: 51px;
  background: linear-gradient(153deg, #ffc84d 0%, #ff6100 100%);
  box-shadow: 0px 2px 10px 1px rgba(245, 96, 14, 0.67);
  border-radius: 8px;
  font-weight: 400;
  color: #ffffff;
  font-size: 18px;
}

/* 签到 */
.register-main {
  width: 100%;
  height: 100%;
  position: relative;
}
.register-bg {
  width: 100%;
}
.register-close {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.register-calendar {
  position: relative;
  top: -67px;
  width: 100%;
  min-height: 100px;
  background-color: #fff;
  border-radius: 39px 39px 0px 0px;
  opacity: 1;
}
.register-calendar-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
}
.register-calendar-title > div {
  font-size: 30px;
  font-weight: bold;
  color: #ed6c4b;
  margin: 0 48px;
}
.register-calendar-title > span {
  width: 54px;
  height: 0px;
  opacity: 1;
  border: 1px solid #ec694d;
}
.register-calendar-main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.register-calendar-week {
  width: 14.285%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
  color: #000;
}
.register-calendar-day {
  width: 14.285%;
  height: 60px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  color: #000;
}
.register-calendar-day-item {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 5px;
  /* cursor: pointer; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lastday {
  color: #dac5c5;
  cursor: auto;
}
.rmb {
  width: 48px;
  height: 48px;
}
</style>
